<template>
	<!-- <TOP title="《葫芦娃2》"></TOP> -->
	<div class="searchFixed">
		<div class="searchBox">	
			<div class="searchDiv">
				<div class="back" @click="gohistory"><img  src="../../assets/images/back_blue.png"/></div>
				<div class="inputdiv"><van-field v-model="searchContent" label="" placeholder="请输入搜索内容" /></div>
				<div class="searchBtn" @click="searchFun">搜索</div>
			</div>
			<div class="quicTag">
				<span @click="changeType('subject')" class="commBtn" :class="type=='subject'?'active':''">标题</span>
				<span @click="changeType('message')" class="commBtn" :class="type=='message'?'active':''">内容</span>
				<span @click="changeType('user')" class="commBtn" :class="type=='user'?'active':''">用户</span>
			</div>
		</div>		
	</div>
	<div class="searchList">
		<TIELIST ref="search" from="search" :keyword="searchContent" :type="type"></TIELIST>
	</div>
	<!--发布按钮-->
	<PUBLISHBTN></PUBLISHBTN>
</template>

<script>
	import {defineComponent} from 'vue';
	// import TOP from '@/components/top/top.vue';
	import { Field,Toast } from 'vant';
	import TIELIST from '@/components/tie/list.vue';
	import PUBLISHBTN from '@/components/publish/pushlistBtn.vue';
	export default defineComponent({
		components: {
			// TOP: TOP,
			[Field.name]:Field,
			TIELIST,
			PUBLISHBTN
		},
		data(){
			return{
				searchContent:'',
				type:'subject'
			}
		},
		mounted() {			
			document.querySelector("body").setAttribute("style",
				"background: linear-gradient(0deg, #EDEDD0, #DBEFE7);");
		},
		unmounted() {
			document.querySelector("body").setAttribute("style",
				"background: linear-gradient(#95C5B5, #64A4A4) no-repeat;");
		},
		created(){
			this.history=this.$route.query.history;
		},
		methods:{
			changeType(type){
				this.type=type;
				if(this.searchContent!=''){
					this.$refs.search.doSearch(this.type);
				}	
			},
			searchFun(){
				if(this.searchContent==''){
					Toast("请输入关键字");
					return;
				}				
				this.$refs.search.doSearch(this.type);
			}
		},
		setup(){
			function gohistory(){
				debugger;
				this.$router.replace(this.history);
			}
			return {
				gohistory
			}
		}
	});
</script>

<style lang="less">
	.searchFixed{
		height:2rem;
	}
	.searchBox{position: fixed;top: 0;left:0;right:0;height: 2rem;background:#DBEFE7;}
	.searchDiv{
		display:flex;
		padding:0.2rem;
		align-items: center;
		.back{
			width:0.4rem;
			flex:none;
			margin-right:0.22rem;
			img{
				width: 100%;
			}
		}
		.inputdiv{
			margin-right: 0.22rem;
			flex:auto;
			height: 0.63rem;
			overflow: hidden;
			.van-cell{
				padding: 0.1rem 0.2rem;
			}
			.van-field__control{
				color:#5D6262;
				font-size:0.31rem;
			}
		}
		.searchBtn{
			width: 1.59rem;
			height: 0.63rem;
			background: #73D1C7;
			box-shadow: 0px 1px 2px 0px rgba(161, 161, 133, 0.36);
			border-radius: 0.32rem;
			color:#F8FAF5;
			text-align: center;
			font-size:0.3rem;
			line-height: 0.63rem;
			flex:none;
		}
	}
	.quicTag{
		padding:0 0.2rem 0.2rem;
		span{
			margin-right: 0.26rem;
			&.active{
				color:#F8FAF5;
				background:#73D1C7;
			}
		}
	}
	.searchList{
		padding:0.2rem;
		position: fixed;
		top: 2rem;
		left: 0;
		right: 0;
		bottom: 0.2rem;
		overflow: auto;
	}
</style>
