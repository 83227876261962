<template>
	<!--发布按钮-->
	<div class="publistBox">
		<img class="publishBtn" src="@/assets/images/f_fabu.png" @click="gopublish"/>
	</div>
</template>

<script>
	import {
		defineComponent
	} from 'vue'
	
	export default defineComponent({
		props:{
			from:{
				type:String,
				default:'/'
			}
		},
		methods:{
			gopublish(){
				this.$router.replace({
					path:'/publish',
					query:{
						history:this.from
					}
				});
			}
		}
	});
</script>

<style lang="less" scoped="scoped">
	.publishBtn{
		position: fixed;
		width: 1.04rem;
		height:1.04rem;
		bottom:2rem;
		right:0.5rem;
	}
</style>
