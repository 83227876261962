<template>
	<div class="tieBox" v-if="from!='home_top'">
		<div class="conditionFixed" v-if="from=='luntang'||from=='gongnue'">
			<div class="conditionBox">
				<div class="tagBox">
					<span v-for="(item,index) in types" :key="index" :class="item.typeid==typeid?'active':''"
						:typeid="item.typeid" @click="typeClick(item.typeid)">{{item.name}}</span>
				</div>
				<div class="dropDownBox">
					<van-dropdown-menu class="rightDropDown" active-color="#73D1C6">
						<van-dropdown-item v-model="orders" :options="ordersArr" />
					</van-dropdown-menu>
				</div>
			</div>
		</div>

		<van-list v-model:loading="loading" :finished="finished" :finished-text="finishTxt" @load="loadData">
			
			<template v-if="searchType!='user'">
				<div class="tieDiv" v-for="(item,index) in listData" :key="index">
					<!--headIcon行-->
					<HEADLINE :author="item.nickname" :authorid="item.authorid" :is_follow="parseInt(item.is_follow)"
						:avatar="item.avatar" :dateline="item.dateline" :user_groupid="item.user_groupid"></HEADLINE>

					<!--置顶大图行-->
					<div class="bigImgDiv" @click="goDeatil(item.tid,item.authorid,'',index)"
						v-if="item.user_groupid=='1'?true:false">
						<img :src="item.imglist[0]" />
						<!--官方标题-->
						<div class="gfTitle" :class="item.displayorder>0?'addpaing':''"><span v-if="item.displayorder>0"
								class="zhiding">置顶</span>{{item.subject}}</div>
					</div>

					<!--标签行-->
					<!-- <div class="tagDiv" @click="goDeatil(item.tid,item.authorid,'',index)" v-if="false">
					<span class="tagitem">#{{item.classname}}</span>
				</div> -->

					<!--文案内容行-->
					<div class="titleDiv" v-if="item.user_groupid=='1'?false:true"
						@click="goDeatil(item.tid,item.authorid,'',index)">{{item.subject}}</div>

					<!--图片列表行-->
					<div class="imgList" v-if="item.user_groupid=='1'?false:true"
						@click="goDeatil(item.tid,item.authorid,'',index)">
						<ul>
							<template v-for="(img,idx) in item.imglist">
								<li :key="idx" v-if="(item.user_groupid=='1'&&idx==0)?false:true">
									<img v-if="idx<3" :src="img" />
								</li>
							</template>

						</ul>
					</div>

					<!--标签行-->
					<div class="tagDiv" v-if="item.classname">
						<span class="tagitem">#{{item.classname}}</span>
					</div>

					<TAGLINE :smData="tabArr" v-if="false"></TAGLINE>

					<!--评论点赞功能区-->
					<PINGLUN :reply_num="parseInt(item.reply_num)" :like_num="parseInt(item.like_num)" :pid="item.pid"
						:index="index" :dolike="item.is_like" :favorite="item.is_favorite" :tid="item.tid"
						:authorid="item.authorid"></PINGLUN>
				</div>
			</template>
			<template v-else>
				<div class="noticeLine" v-for="(item,index) in listData" :key="index">
					<div class="noticeLeft">
						<div class="noticeImg"><img :src="item.avatar" /></div>
						<div class="tiezhuzhe">
							<div class="name">
								{{item.nickname}}
							</div>
							<div class="time" v-if="false">官方点赞，给你欧气加持~官方点赞，给你欧气加持~官方点赞，给你欧气加持~官方点赞，给你欧气加持~</div>
						</div>
					</div>
					<div class="gongzhuBtn" v-if="ismy!=item.uid"  @click="doNotice(item.uid,index)">{{item.is_follow==1?'已关注':'关注'}}</div>
				</div>
			</template>
		</van-list>
	</div>
	<DETAIL v-if="showDetail" :tid="detailTid" :authorid="detailAuthorid" :showPL="showPL" :index="detailIndex">
	</DETAIL>
	<div class="gonggao" v-if="topList.length>0&&from=='home_top'">
		<template v-for="(item,index) in topList">
			<div class="gonggaoItem" href="javascript:void(0)" :key="index" v-if="index<3"
				@click="goDeatil(item.tid,item.authorid,'',index)">
				<div>【公告】{{item.subject}}</div>
			</div>
		</template>
	</div>
</template>

<script>
	import {
		List,
		DropdownMenu,
		DropdownItem,
		Toast
	} from 'vant';
	import {
		defineComponent
	} from 'vue'
	import HEADLINE from '@/components/headLine/headLine.vue'
	import PINGLUN from '@/components/pinglun/pinglun.vue'
	import TAGLINE from '@/components/tagLine/tagLine.vue'
	import DETAIL from '@/components/detail/detail.vue'

	import {
		useRouter,
		useRoute
	} from 'vue-router'
	import request from '@/service/request'
	import common from '@/util/common';

	export default defineComponent({
		components: {
			HEADLINE,
			PINGLUN,
			[List.name]: List,
			[DropdownMenu.name]: DropdownMenu,
			[DropdownItem.name]: DropdownItem,
			TAGLINE,
			DETAIL
		},
		data() {
			return {
				// / finished:false,//是否已加载完成，加载完成后不再触发 load 事件
				loading: false, //是否处于加载状态，加载过程中不触发 load 事件
				listData: [],
				page: 1,
				finishTxt: "没有更多了",
				finished: false,
				ordersArr: [{
					text: '最新发布',
					value: 0
				}],
				tabArr: [],
				fid: '',
				types: [],
				orders: 0,
				typeid: '',
				showDetail: false,
				detailTid: '',
				detailAuthorid: '',
				showPL: false,
				topList: [],
				searchType: '',
				ismy:''
			}
		},
		props: {
			//是否已加载完成，加载完成后不再触发 load 事件
			from: {
				type: String,
				default: '' //
			},
			history: {
				type: String,
				default: '' //
			},
			isContent: {
				type: Boolean,
				default: true
			},
			type: {
				type: String,
				default: ''
			},
			keyword: {
				type: String,
				default: ''
			}
		},
		watch: {
			type(newVal, oldVal) {
				console.log(newVal,oldVal);
				this.searchType = newVal;
			}
		},
		created() {
			this.ismy=JSON.parse(window.localStorage.getItem('userInfo')).uid;
			this.searchType = this.type;
			this.getData();
		},
		methods: {
			loadData() {
				if (this.from == "home_content" && this.page != 1) {
					this.getNewList();
				}
				if (this.from == "gongnue" || this.from == "luntang") {
					this.getSubList();
				}
				if (this.from == 'my_shouchang' && this.page != 1) {
					this.getShouChangList();
				}
				if (this.from == 'search' && this.page != 1) {
					this.getSearchList();
				}
			},
			getTypes(fid) {
				request.apiAxios({
					method: 'post',
					url: request.GETTYPES,
					data: {
						fid: fid
					}
				}).then((res) => {
					if (res.status) {
						console.log(res);
						this.types = res.data;
						this.typeid = res.data[0].typeid;
						this.getSubList();
					}
				}).catch((err) => {
					console.log(err);
					this.finished = true;
				})

			},
			getData() {
				if (this.from == 'home_top') {
					this.getTopList();
				}
				if (this.from == 'home_content') {
					this.getNewList();
				} else if (this.from == 'home_guangfang') {
					this.finishTxt = '置顶加载完成！';
					this.finished = true;
				} else if (this.from == 'gongnue') {
					this.fid = '893';
					this.getTypes('893');
				} else if (this.from == "luntang") {
					this.fid = '892';
					this.getTypes('892');
				} else if (this.from == 'my_shouchang') {
					this.getShouChangList();
				} else if (this.from == 'search') {
					this.finished = true;
					this.finishTxt = ''
				}
			},
			doSearch(type) {
				this.finishTxt = '没有更多了';
				this.page = 1;
				this.searchType = type;
				this.getSearchList();
			},
			//搜索列表
			getSearchList() {
				request.apiAxios({
					method: 'post',
					url: request.SEARCH,
					data: {
						page: this.page,
						type: this.searchType, //user（搜索用户），subject（搜索标题）, message（搜索内容）
						keyword: this.keyword
					}
				}).then((res) => {
					if (res.status) {
						if (this.page == 1) {
							this.listData = [];
						}
						if (res.data.list.length > 0) {
							if (this.listData.length == 0) {
								this.listData = res.data.list;
							} else {
								this.listData = this.listData.concat(res.data.list);
							}
							this.finished = false;
						} else {
							this.finished = true;
						}
						this.page++;
					} else {
						Toast(res.message)
					}
					this.loading = false;
				}).catch((err) => {
					console.log(err);
					this.finished = true;
				})
			},
			//收藏列表
			getShouChangList() {
				request.apiAxios({
					method: 'post',
					url: request.FAVORITELIST,
					data: {
						page: this.page
					}
				}).then((res) => {
					if (res.status) {
						if (res.data.list.length > 0) {
							if (this.listData.length == 0) {
								this.listData = res.data.list;
							} else {
								this.listData = this.listData.concat(res.data.list);
							}
							this.finished = false;
						} else {
							this.finished = true;
						}
						this.page++;
					}
					this.loading = false;
				}).catch((err) => {
					console.log(err);
					this.finished = true;
				})
			},
			//首页最新列表
			getNewList() {
				request.apiAxios({
					method: 'post',
					url: request.NEWLIST,
					data: {
						page: this.page
					}
				}).then((res) => {
					if (res.status) {
						if (res.data.list.length > 0) {
							if (this.listData.length == 0) {
								this.listData = res.data.list;
							} else {
								this.listData = this.listData.concat(res.data.list);
							}
							this.finished = false;
						} else {
							this.finished = true;
						}
						this.page++;
					}
					this.loading = false;
				}).catch((err) => {
					console.log(err);
					this.finished = true;
				})
			},
			getSubList() {
				if (!this.typeid) {
					return;
				}
				request.apiAxios({
					method: 'post',
					url: request.SUBINDEX,
					data: {
						fid: this.fid,
						typeid: this.typeid,
						page: this.page
					}
				}).then((res) => {
					if (res.status) {
						if (res.data.list.length > 0) {
							if (this.listData.length == 0 || this.page == 1) {
								this.listData = res.data.list;
							} else {
								this.listData = this.listData.concat(res.data.list);
							}
							this.finished = false;
						} else {
							this.finished = true;
						}
						this.page++;
					}
					this.loading = false;
				}).catch((err) => {
					console.log(err);
					this.finished = true;
				})
			},
			//置顶链接
			getTopList() {
				request.apiAxios({
					method: 'post',
					url: request.TOPLIST,
					// data: {
					// 	token:this.mobile
					// }
				}).then((res) => {
					console.log(res);
					if (res.status) {
						this.topList = res.data.list;
					} else {
						console.log(res.message)
					}

				}).catch((err) => {
					console.log(err);
				})
			},
			//类型点击事件
			typeClick(typeid) {
				console.log(typeid, 'typeid')
				this.typeid = typeid;
				this.page = 1;
				this.listData = [];
				this.getSubList();
			},
			goDeatil(tid, authorid, showPL, index) {
				if (showPL) this.showPL = showPL;
				else this.showPL = false;
				if (this.isContent) {
					this.showDetail = true;
					this.detailTid = tid;
					this.detailAuthorid = authorid;
					this.detailIndex = index;
				} else {
					this.goDeatil2(tid, authorid);
				}
			},
			closeDeatil() {
				this.showDetail = false;
				this.detailTid = '';
				this.detailAuthorid = '';
			},
			modifyDianZan(index, value) {
				this.listData[index].is_like = value;
				if (value) {
					this.listData[index].like_num++;
				} else {
					this.listData[index].like_num--;
				}
			},
			modifyShouchang(index, value) {
				this.listData[index].is_favorite = value;
			},
			doFollowSet(authorid, type) {
				for (var i = 0; i < this.listData.length; i++) {
					if (this.listData[i].authorid == authorid) {
						this.listData[i].is_follow = type;
					}
				}
			},
			modifyReplyNum(index, value) {
				this.listData[this.detailIndex].reply_num = value;
			},
			doNotice(follow_uid, index) {
				if (common.isLogin()) {
					var smData = {};
					smData.token = window.localStorage.getItem('token');
					smData.follow_uid = follow_uid;
					request.apiAxios({
						method: 'post',
						url: request.FOLLOW,
						data: smData
					}).then((res) => {
						if (res.status) {
							if (res.data.is_follow == 1) {
								this.listData[index].is_follow = 1;
								Toast("关注成功！");
							} else {
								this.listData[index].is_follow = 0;
								Toast("取消关注成功！");
							}
			
						} else {
							Toast(res.message);
						}
					}).catch((err) => {
						console.log(err);
					})
				}
			}

		},
		setup() {
			const router = useRouter();
			const route = useRoute();

			function goDeatil2(tid, authorid) {
				router.replace({
					path: '/content',
					query: {
						tid: tid,
						authorid: authorid,
						history: this.history
					}
				})
			}
			return {
				goDeatil2
			}
		},

	})
</script>

<style lang="less" scoped="scoped">
	.topBox {
		height: 1rem;

		.topDiv {
			position: fixed;
			bottom: 0;
			left: 0;
			right: 0;
			height: 1rem;
			display: flex;
			align-items: center;
			justify-content: space-around;
			background: linear-gradient(0deg, #95C5B5, #64A4A4);
			box-shadow: 0px 2px 19px 0px rgba(66, 135, 142, 0.67);

			a {
				color: #F8FAF5;
			}

			img {
				height: 0.23rem;
				margin-right: 0.2rem;
			}
		}
	}

	.tieBox {
		.bigImgDiv {
			min-height: 1.33rem;

			img {
				vertical-align: bottom;
			}

			.gfTitle {
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				background: url(../../assets/images/zzbg.png) repeat-x center bottom;
				background-size: 0.9rem;
				height: 1.33rem;
				border-radius: 0.14rem;
				color: #fff;
				padding: 0.2rem 0.2rem 0.2rem 0.2rem;
				margin-right: 0;
				display: flex;
				align-items: flex-end;
				overflow: hidden;
				// text-overflow:ellipsis;
				white-space: nowrap;
				font-size: 0.24rem;
			}

			.addpaing {
				padding: 0.2rem 0.2rem 0.2rem 1.2rem;
			}

			.zhiding {
				position: absolute;
				left: 0.2rem;
				background: #099593;
				border-radius: 0.19rem;
				padding: 0.02rem 0.15rem;
				color: #FFFFFF;
				font-size: 0.2rem;
				bottom: 0.2rem;
			}
		}

		.conditionFixed {
			height: 1.6rem;
			position: relative;
			z-index: 100;
		}

		.conditionBox {
			padding: 0 0.23rem;
			position: fixed;
			top: 0.88rem;
			left: 0;
			right: 0;
			height: 1.6rem;
			background: #DBEFE7;
		}

		.tagBox {
			width: 100%;
			overflow-x: auto;
			padding: 0.23rem 0;
			display: flex;

			span {
				height: 0.64rem;
				background: #73D1C6;
				box-shadow: 0px 1px 2px 0px rgba(161, 161, 133, 0.36);
				border-radius: 0.32rem;
				font-size: 0.3rem;
				text-align: center;
				padding: 0.17rem 0.22rem;
				display: inline-flex;
				align-items: center;
				color: #00656E;
				margin-right: 0.27rem;
				flex: none;

				&.active {
					color: #F8FAF5;
				}
			}
		}

		.rightDropDown {
			display: flex;
			justify-content: flex-end;

			&>div {
				background: transparent;
				box-shadow: none;
			}

			.van-dropdown-menu__bar {
				width: auto;
			}

			.van-dropdown-menu__item {
				padding-right: 0.3rem;
				justify-content: flex-end;
			}

			.van-ellipsis {
				font-size: 0.26rem;
				color: #5D6262;
			}

			.van-dropdown-menu__title:after {
				border-color: transparent transparent #5D6262 #5D6262;
			}
		}
	}

	.tieDiv {
		background: #F8FAF5;
		box-shadow: 0px 1px 2px 0px rgba(161, 161, 133, 0.36);
		padding: 0.22rem 0.2rem;
		border-radius: 0.14rem;
		margin-bottom: 0.2rem;


		.bigImgDiv {
			border-radius: 0.14rem;
			margin-bottom: 0.13rem;
			position: relative;

			img {
				max-width: 100%;
				border-radius: 0.14rem;
			}
		}

		.tagDiv {
			margin-bottom: 0.13rem;
		}

		.tagitem {
			min-width: 1.64rem;
			background: #B4EDE2;
			border-radius: 0.25rem;
			text-align: center;
			;
			color: #227D7C;
			padding: 0.05rem 0.28rem;
		}





		.imgList {
			margin-bottom: 0.15rem;

			ul {
				margin: 0 !important;
				display: flex;
				overflow: hidden;
			}

			li {
				width: 33.33%;
				flex: none;
				padding: 0.05rem 0.15rem 0px 0px !important;

				img {
					max-width: 100%;
					border-radius: 0.14rem;
				}
			}
		}

		.titleDiv {
			color: #5D6262;
			font-size: 0.3rem;
			margin: 0.3rem 0;
			word-wrap: break-word;
			white-space: normal;
			word-break: break-all;
		}

	}

	.gonggao {
		background: #F8FAF5;
		box-shadow: 0px 1px 2px 0px rgba(161, 161, 133, 0.36);
		border-radius: 0.14rem;
		padding: 0.33rem 0.23rem;
		font-size: 0.3rem;
		margin-bottom: 0.2rem;

		.gonggaoItem {
			margin-bottom: 0.15rem;
			width: 100%;
			overflow: hidden;
			white-space: nowrap;
			display: block;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.noticeLine {
		display: flex;
		align-items: center;
		margin-bottom: 0.26rem;
		background: #F8FAF5;
		box-shadow: 0px 1px 2px 0px rgba(161, 161, 136, 0.36);
		border-radius: 0.14rem;
		padding: 0.16rem 0.22rem;
	}

	.noticeLeft {
		margin-right: 0.2rem;
		flex: auto;
		display: flex;
		color: #5D6262;
	}

	.noticeImg {
		width: 0.88rem;
		height: 0.88rem;
		background: #EBEDDE;
		border-radius: 0.07rem;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 0.2rem;

		img {
			max-width: 100%;
			max-height: 100%;
			border-radius: 0.07rem;
		}
	}

	.tiezhuzhe {
		display: flex;
		flex-direction: column;
		overflow: hidden;
		white-space: nowrap;
		max-width: 3.5rem;

		.name {
			display: flex;
			align-items: center;
			font-size: 0.34rem;
			margin-bottom: 0.14rem;
			color: #5D6262;
		}

		.time {
			font-size: 0.25rem;
			color: #5D6262;
		}
	}

	.guangfangtag {
		width: 0.78rem;
		height: 0.38rem;
		margin-left: 0.4rem;
		flex: none;
	}

	.gongzhuBtn {
		flex: none;
		width: 1.22rem;
		height: 0.64rem;
		background: #73D1C7;
		box-shadow: 0px 1px 2px 0px rgba(161, 161, 133, 0.36);
		border-radius: 0.32rem;
		line-height: 0.64rem;
		text-align: center;
		color: #F8FAF5;
		font-size: 0.3rem;
	}
</style>
<style lang="less">
	.tieBox {
		.van-dropdown-menu__title {
			color: #5D6262;
		}
	}
</style>
